var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',[(!_vm.fromQuickAction)?[(_vm.isActive)?_c('span',{staticClass:"setting-icon",on:{"click":_vm.openSettings}},[_c('i',{staticClass:"el-icon-s-tools"})]):_vm._e(),(
          _vm.data &&
          _vm.data.action_button_type &&
          _vm.data.action_button_type == 'HYPERLINK'
        )?_c('el-link',{style:(_vm.hideStyles ? _vm.getGalleryStyles : _vm.getStyle),attrs:{"loading":_vm.loading,"disabled":_vm.checkReadonly},on:{"click":_vm.action}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_c('el-button',{style:(_vm.hideStyles ? '' : _vm.getStyle),attrs:{"loading":_vm.loading,"round":_vm.isRound,"disabled":_vm.checkReadonly},on:{"click":_vm.action}},[_vm._v(_vm._s(_vm.label || _vm.data.label))])]:_vm._e(),_c('dialog-component',{attrs:{"title":_vm.viewDataOnly || _vm.viewDataAndAdd
          ? 'Related ' + (_vm.relationshipDetails.relationship_title || 'data')
          : _vm.allowNewAdd
          ? 'Add or assign'
          : 'Assign',"visible":_vm.openRelationalModal,"containerMaxHeight":'90%'},on:{"before-close":_vm.resetAddModal}},[(_vm.relationshipDetails && _vm.openRelationalModal)?_c('quick-entity-filter',{attrs:{"entityId":_vm.relationshipDetails.relationship_id,"relationshipDetails":_vm.relationshipDetails,"allowNewAdd":_vm.allowNewAdd,"actionButtonField":_vm.data,"viewDataOnly":_vm.viewDataOnly,"viewDataAndAdd":_vm.viewDataAndAdd,"allowNewAddOnly":_vm.allowNewAddOnly,"parentFields":_vm.fieldsData,"parentForm":_vm.form,"parentEntityData":_vm.entityData},on:{"quickAddClose":function($event){return _vm.resetAddModal(true)},"entityDataSelect":_vm.updateEntityData}}):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.resetAddModal}},[_vm._v(" "+_vm._s(_vm.viewDataOnly || _vm.viewDataAndAdd ? "Close" : "Cancel")+" ")]),(!_vm.viewDataOnly && !_vm.viewDataAndAdd)?_c('el-button',{attrs:{"type":"primary","disabled":_vm.checkDisabled,"loading":_vm.loading},on:{"click":_vm.establichRelationship}},[_vm._v("Save")]):_vm._e()],1)],1),_c('dialog-component',{attrs:{"title":"Add entity data","visible":_vm.openOtherEntityModal,"containerMaxHeight":'70%'},on:{"before-close":_vm.resetOtherEntityModal}},[_c('quickEntityAddWidget',{attrs:{"entityId":_vm.selectedOtherEntityId,"actionButtonField":_vm.data,"currentEntity":_vm.currentEntity,"openNewAdd":true,"relationshipDetails":[],"detailed_add":true,"parentFields":_vm.fieldsData,"parentForm":_vm.form,"parentEntityData":_vm.entityData,"isFromAddOtherEntity":_vm.isFromAddOtherEntity,"otherEntityDataId":_vm.parent_entityDataId,"isUpdateEntityData":_vm.isUpdateEntityData},on:{"onNewEntity":_vm.resetOtherEntityModal}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.resetOtherEntityModal}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary"}},[_vm._v("Save")])],1)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }